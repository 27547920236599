<template>
  <section class="breadcrumbs">
    <div class="container">
      <ol class="breadcrumb pb-0 pt-0">
        <li
          class="breadcrumb-item"
          v-for="(item, index) in items"
          :key="index"
          :class="{ active: index + 1 == items.length }"
        >
          <router-link v-if="!item.active" :to="item.to">{{ item.text }}</router-link>
          <span v-else>{{ item.text }}</span>
        </li>
      </ol>
    </div>
  </section>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const items = computed(() => {
  const items = [];
  const bcrumbs = store.getters["breadcrumbs/getBreadcrumbsRoutes"];
  items.push({ text: "Home", to: "/" });
  bcrumbs.forEach((bcrumb) => {
    items.push({ text: bcrumb.name, to: bcrumb.route_link });
  });
  const curr = store.getters["breadcrumbs/getBreadcrumbsCurrent"];
  items.push({ text: curr, active: true });
  return items;
});
</script>
